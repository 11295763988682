<template>
    <div style="min-height:100%; background: #fff;padding: 0px 20px 0;margin: 0;">
        <a-layout-content style="height: 100%; width: 100%;background:#fff">

            <a-form layout="horizontal" style="padding: 10px 0px 0; height: auto">
                <!-- <a-tabs default-active-key="0">
                    <a-tab-pane key="0" tab="支付" />
                </a-tabs> -->
            </a-form>
            <div id="Invitation" v-if="showcontent">
                <div class="l_invitation_pay">
                    <div class="l_invitation_pay_block">
                        <div class="l_invitation_pay_block_title" style="width:200px;height:20px;font-size:20px">
                            红包充值
                        </div>
                        <div class="l_invitation_pay_block_price" style="font-size:50px"><span>￥</span>{{SumMoney}}</div>

                    </div>
                    <div class="l_invitation_pay_msg">
                        <span style="font-size:15px">
                            包含手续费{{ServiceCharge}}元，不足0.1按0.1收取
                        </span>
                    </div>
                    <div class="l_invitation_next_btn">
                        <button v-on:click="CreateOrder()">支付</button>
                    </div>
                </div>
            </div>

            <div v-if="showPayurl">
                <a-modal centered
                         width="400"
                         :visible="visible"
                         :closable="false"
                         :footer="null" @cancel="handleCancel">
                    <iframe id="home" name="home" :src="RedPayUrl" frameborder="0" width="700" height="400" style="overflow:hidden;margin:65px 0px;padding-bottom:65px;">
                    </iframe>
                </a-modal>

            </div>



        </a-layout-content>


    </div>

</template>
<script>
    import http from "@/Plugin/Http.js";
    //import util from "@/Plugin/util.js";


    export default {
        name: "PackageList",
        data() {
            return {
                SumMoney: 0,
                ServiceCharge: 0,
                PayMentMoney: 0,
                RedPrize: 0,
                AwardPrize: 0,
                Rate: null,
                visible: false, //绑定支付弹窗
                RedPayUrl: "",
                showPayurl: false,
                showcontent: true,
             
            };
        },
        props: {
            //组件属性

        },
        methods: {
            
            //获取手续费率
            GetRate() {
                var self = this;
                var op = {
                    url: "/MarketingModule/Promote/GetRate",
                    data: {
                    },
                    OnSuccess: function (data) {
                        var obj = data.data.Data;
                        for (var i = 0; i < obj.length; i++) {
                            self.CouponsDataList = data.data.Data;
                        }
                    }
                };
                http.Post(op);
            },

            CreateOrder: function () {
                var self = this;
                var InvitationTitle = this.getlocal('InvitationTitle');
                var InvitationRule = this.getlocal('InvitationRule');
                var StartTime = this.getlocal('StartTime');
                var EndTime = this.getlocal('EndTime') + " 23:59:59";
                var Lat = this.getlocal('Lat');
                var Lng = this.getlocal('Lng');
                var MapDistanceStr = this.getlocal('MapDistanceStr');
                var GeographyLimit = this.getlocal('GeographyLimit');
                var InitialLuckDrawCount = this.getlocal('InitialLuckDrawCount');
                var MaxWinningPrizeCount = this.getlocal('MaxWinningPrizeCount');
                var HelpLuckDrawCount = this.getlocal('HelpLuckDrawCount');
                var HelpMaxCount = this.getlocal('HelpMaxCount');
                var PrizeList = this.getlocal('PrizeList');
                var CenterPointAddress = this.getlocal('CenterPointAddress');
                var PrizeModel = JSON.parse(PrizeList);
                var Mid = this.getlocal('MaterialID');
                var MapDistance = (MapDistanceStr).replace(/[^0-9]/ig, "") / 1000;
                var InviteIntroducerMoney = this.getlocal('InviteIntroducerMoney');
                var InviteSharerMoney = this.getlocal('InviteSharerMoney');
                var FissionShareType = this.getlocal('FissionShareType');

                var op = {
                    url: "/MarketingModule/Promote/AddTurnOrder",
                    data: {
                    Name: InvitationTitle, StartTime: StartTime, EndTime: EndTime,
                    RechargeMoney: self.RedPrize, TempTurnAward: PrizeModel,
                    TurnSummary: InvitationRule, GeographyLimit: GeographyLimit, Lat: Lat != "" ? Lat : 36.062233, Lng: Lng != "" ? Lng:114.383176,
                    InitialLuckDrawCount: InitialLuckDrawCount, MaxWinningPrizeCount: MaxWinningPrizeCount,
                    HelpLuckDrawCount: HelpLuckDrawCount != "" ? HelpLuckDrawCount : 0,
                    HelpMaxCount: HelpMaxCount != "" ? HelpMaxCount : 0, MaterialID: Mid, MapDistance: MapDistance,
                        CenterPointAddress: CenterPointAddress, InviteIntroducerMoney: InviteIntroducerMoney, InviteSharerMoney: InviteSharerMoney,
                        FissionShareType:FissionShareType,HoldType:0,IsSetPartakeReward:0,ReceiveType:0,PartakeRewardMoney:0
                    },
                    OnSuccess: function (data) {
                        if (self.SumMoney == 0) {
                            var op1 = {
                                url: "/MarketingModule/Promote/TurnTablePayZero",
                                data: {
                                    OrderInfoID: data.data.Data,
                                },
                                OnSuccess: function (data) {
                                    console.log("只有优惠券", data);
                                    self.RedPayUrl = data.data.Data;
                                    console.log("self.RedPayUrl", self.RedPayUrl);
                                    self.$message.success("保存成功");
                                    self.$router.push({
                                        name: "InteractionConfig_List",
                                    })
                                }
                            };
                            http.Post(op1);
                        }
                        else {
                            var op2 = {
                                url: "/MarketingModule/Promote/PayUrl",
                                data: {
                                    OrderID: data.data.Data,
                                },
                                OnSuccess: function (data) {
                                    console.log(data);
                                    self.RedPayUrl = data.data.Data;
                                    console.log("self.RedPayUrl", self.RedPayUrl);
                                    //主支付页面隐藏
                                    //self.showcontent = false;
                                    ////显示iframe(对应支付成功页)
                                    self.showPayurl = true;
                                    self.visible = true;
                                }
                            };
                            http.Post(op2);

                        }
                    }
                };
                http.Post(op);


                return 0;

            },

            //绑定支付取消事件
            handleCancel() {
                this.visible = false;
            },

            //isBindTop() {
            //    var self = this;
            //    var op =
            //    {
            //        url: "/PartnerModule/PartnerList/IsBindTop",
            //        data: {
            //        },
            //        OnSuccess: function (res) {
            //            self.isShowOperation = res.data;
            //        }
            //    };
            //    http.Post(op);

            //},

            ////顶级合伙人身份绑定
            //bindtop() {
            //    var self = this;
            //    if (!util.isBlank(self.BindTopImg)) {
            //        self.visible = true;
            //        return;
            //    } else {
            //        self.visible = true;
            //        var op =
            //        {
            //            url: "/PartnerModule/PartnerList/BindTopImg",
            //            data: {
            //            },
            //            OnSuccess: function (res) {
            //                self.BindTopImg = res.data
            //            }
            //        };
            //        http.Post(op);
            //    }
            //},

          
            setlocal: function (cname, cvalue) {
                localStorage.setItem(cname, cvalue);
            },
            getlocal: function (cname) {
                return localStorage.getItem(cname);
            },
        },
        computed: {
            //计算属性
        },
        watch: {
        },
        components: {
        },
        beforeCreate: function () {
            //生命周期，创建前
        },
        created: function () {
            //生命周期，创建完成
        },
        mounted: function () {
            this.GetRate();
            var PrizeList = this.getlocal('PrizeList');
            var PrizeModel = JSON.parse(PrizeList);
            console.log("PrizeModel", PrizeModel);
            if (PrizeModel != null) {
                for (var i = 0; i < PrizeModel.length; i++) {

                    if (PrizeModel[i].AwardType == 0) {
                        this.RedPrize = ((PrizeModel[i].AmountMoney * 100) * (PrizeModel[i].AwardCount * 100)) / 10000;
                    }
                    if (PrizeModel[i].awardtype == 1) {
                        this.awardprize = PrizeModel[i].awardmoney * PrizeModel[i].awardcount;
                    }
                }
            }
            var self = this;
            setTimeout(function () {
                console.log(self.RedPrize + "/*/*" + self.Rate);
                if (self.RedPrize > 0 && self != null) {
                    self.ServiceCharge = NewCalculationRate(self.RedPrize, self.Rate);
                    self.SumMoney = (parseFloat(self.RedPrize * 1000) + parseFloat(self.ServiceCharge * 1000)) / 1000;
                }
            }, 100);

              //this.isBindTop();

        },
    };
    //新计算汇率 m金额 r汇率
    function NewCalculationRate(m, r) {
        var num = m * r;
        if (num <= 0.1) {
            return 0.1;
        } else {
            var su = num.toString().indexOf(".");
            if (su > 0) {
                var nu = num.toString().split(".");
                var leftnum = nu[0];
                var rightnum = (parseInt(("0." + nu[1].toString()) * 100)) / 10;
                var onenum = rightnum.toString().split(".");
                if (parseInt(onenum[1]) > 0) {
                    //console.log("abv:",parseInt(leftnum) + 1);
                    if (parseInt(onenum[0]) == 9) {
                        return parseInt(leftnum) + 1;
                    } else {
                        return leftnum + "." + (parseInt(onenum[0]) + 1);
                    }
                } else {
                    return leftnum + "." + parseInt(onenum[0]);
                }
            } else {
                return num;
            }
        }
    }
</script>

<style scoped>
    .muban {
        display: grid;
        grid-template-columns: repeat(6,1fr);
        gap: 25px;
        padding: 25px;
        width: 100%;
        margin: 0 auto;
    }

    .muban-item {
        border: 1px solid #f4f4f4;
        border-radius: 8px;
        overflow: hidden;
        max-height: 505px;
    }

        .muban-item:hover {
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
            transition: all ease 0.3s;
        }

            .muban-item:hover .sc-txt {
                color: #2281f8
            }

    .sc-txt {
        display: block;
        font-size: 16px;
        line-height: 20px;
        color: #3B3B3B;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
        cursor: pointer;
        padding: 8px 5px;
    }

        .sc-txt:hover {
            color: #2281f8
        }

    ::v-deep .ant-card-body {
        padding: 5px
    }

    .mintro {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        text-align: left !important;
        margin: 0;
        font-size: 12px;
        white-space: normal;
    }

    .mintro2 {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        text-align: left !important;
        margin: 0;
        font-size: 12px;
        height: 36px;
        white-space: normal;
    }

    .thumb-img1 {
        height: 150px;
        object-fit: cover;
        outline: none;
        background: url("../../../../src/assets/nourl.png");
        background-size: cover;
    }

    .thumb-img {
        cursor: pointer;
        min-height: 385px;
        border-radius: 5px;
        width: 100%;
        outline: none;
        border: none;
        border-radius: 10px 10px 0 0;
        background: #EBEBEB url("../../../../src/assets/nourl.png") no-repeat center center;
        background-size: contain;
        background-repeat: no-repeat;
    }

        .thumb-img:hover {
            opacity: 0.85;
        }

    .option {
        margin: 10px 0;
    }
    .l_invitation_pay{
        text-align: center;
    }
    .l_invitation_pay_block_title{
        margin-bottom: 20px;
    }
    .l_invitation_pay_msg{
        margin-top: 10px;
    }
    .l_invitation_next_btn{
        margin-top: 150px;
    }
    .l_invitation_pay_block_price{
        color: #fd3636;
        font-weight: bold;
    }
    .l_invitation_pay_block_price span{
        font-size:22px;
        font-weight: normal;
    }
    .l_invitation_next_btn button{
        width: 130px;
        height: 35px;
        border: none;
        background: #2281f8;
        color: #fff;
        border-radius: 3px;
        font-size: 16px;
    }
</style>
